.avatar-circle {
    border-radius: 50%;
    height: var(--avatar-size-s, var(--avatar-size, 29px));
    width: var(--avatar-size-s, var(--avatar-size, 29px));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.img-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
    border: var(--avatar-border, 0);
    position: relative;
}

.image {
    position: absolute;
    display: block;
    inset: -100%;
    margin: auto;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center top;
    border-radius: 50%;
}

.emptyCell__background {
    background-color: #005b70;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyCell__text {
    color: white;
}