.project-form-instance {
    padding: 50px 120px 20px 120px;
    background-color: #F6F6F6;
}

.vf-units-label {
    color: #9D9A9A;
    font-family: Georgia;
    font-size: 17px;
    line-height: 19px;
    font-weight: 700;
    min-width: 140px;
}

.vf-units {
    color: #9D9A9A;
    font-family: 'museo-sans';
    font-size: 12px;
    font-weight: 600;
    height: 36px;
    overflow-y: auto;
}

.vf-form-title {
    color: #000000;
    font-family: Georgia;
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
}

.vf-form-description {
    margin-top: 9px;
    font-weight: 600;
    font-family: 'museo-sans';
    color: #595959;
    font-size: 14px;
    line-height: 17px;
    max-height: 85px;
    overflow-y: auto;
  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.field__wrapper {
    display: flex; 
    justify-content: center; 
    width: 100%;
}