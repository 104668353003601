.wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 35px;
}
 
.title {
    font-size: '21px';
}

.button {
    width: 100px;
    margin-left: 30px !important;
}

.box__wrapper {
    border-bottom: 1px solid #ADADAD;
}

.pageButtonAddFormIcon {
    color: #6BAEB4 !important;
}

.post::after {
    position: absolute;
    left: 65px;
    content: " ";
    display: block;
    border-bottom: 1px solid #ADADAD;
    width: 90%;
}

span p {
    margin-bottom: 0rem;
}