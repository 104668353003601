.table__header {
    width: 10%;
    text-align: center;
}

.tableCenter__cell {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 10%;
    flex-shrink: 0;
}
.clientSituationContainer {
    position: relative;
    width: 100vw;
}

span p {
    display: inline-block;
}